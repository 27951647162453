<template>
    <div class="preview-container">
        <div class="preview-ui">
            <div class="top-elements">
                <div class="top-info">
                    <img
                        class="logo"
                        :src="pageLogo">
                    <div class="top-desc">
                        <div class="top-title">
                            {{ pageName }}
                        </div>
                        <div class="top-sponsored">
                            Sponsored
                        </div>
                    </div>
                </div>
                <div class="top-dots">
                    <icon
                        class="top-dot"
                        size="7"
                        color="black"
                        name="circle" />
                    <icon
                        class="top-dot"
                        size="7"
                        color="black"
                        name="circle" />
                    <icon
                        class="top-dot"
                        size="7"
                        color="black"
                        name="circle" />
                    <icon
                        class="top-dot ml-4"
                        size="18"
                        color="black"
                        name="close" />
                </div>
            </div>
            <div class="description">
                <span>{{ sanitizedPrimaryText }}</span>
                <span
                    v-if="!showmore && desc"
                    class="showmore"
                    @click="showmore = true">See more</span>
            </div>

            <div class="facebook-preview-carousel-mobile">
                <carousel
                    :per-page="1"
                    :mouse-drag="true"
                    :navigation-enabled="true"
                    :pagination-enabled="false"
                    navigation-next-label=""
                    navigation-prev-label=""
                    @pageChange="slideChange">
                    <slide
                        v-for="(slide, index) in creatives"
                        :key="index">
                        <div class="block">
                            <div class="image-holder">
                                <div
                                    v-if="slide.media_type === 'VIDEO'"
                                    :ref="'video-icon'+index"
                                    class="play-wrapper"
                                    @click="playVideo(index)">
                                    <div class="play">
                                        <icon
                                            size="40"
                                            color="white"
                                            name="audio-play" />
                                    </div>
                                </div>
                                <video
                                    v-if="slide.media_type === 'VIDEO'"
                                    :ref="'video'+index"
                                    class="preview-video"
                                    controls>
                                    <source
                                        :src="slide.media_url"
                                        type="video/mp4">
                                </video>
                                <img
                                    v-else
                                    class="preview-image"
                                    :src="slide.media_url">
                            </div>
                            <div class="block-elements">
                                <div class="block-info">
                                    <div class="block-title">
                                        {{ sanitizedHeadline }}
                                    </div>
                                    <div class="block-desc">
                                        {{ sanitizedDescription }}
                                    </div>
                                </div>
                                <div class="block-link">
                                    <a
                                        :href="slide.cta_link"
                                        target="_blank">{{ slide.cta_text }}</a>
                                </div>
                            </div>
                        </div>
                    </slide>
                </carousel>
                <div class="slide-num">
                    <span>{{ currentSlide }}</span> of <span>{{ slideLength }}</span>
                </div>
            </div>

            <div class="border" />

            <div class="low-elements">
                <icon
                    size="29"
                    color="#606770"
                    name="thumbsup" />
                <icon
                    size="29"
                    color="#606770"
                    name="comments" />
                <icon
                    size="29"
                    color="#606770"
                    name="arrow-share" />
            </div>
        </div>
    </div>
</template>

<script>
import Icon from '@/components/globals/Icon';
import { Carousel, Slide } from 'vue-carousel';
import { lengthValidatorConstants } from '@/validators/granular/lengthValidator';
import { mapGetters, mapState } from 'vuex';
import {
    META,
    primaryTextKey,
    headlineKey,
    descriptionKey
} from '@/components/ad-studio/store/constants';

export default {
    components: {
        Icon,
        Carousel,
        Slide,
    },
    props: {
        adData: {
            type: [Object, Array],
            required: true
        }
    },
    data() {
        return {
            desc: '',
            showmore: false,
            currentSlide: 1,
            slideLength: 0,
            defaultLogoUrl: 'https://www.facebook.com/images/fb_icon_325x325.png',
        };
    },
    computed: {
        ...mapState({
            currentDealer: (state) => state.dealer.currentDealer,
        }),
        ...mapGetters(['dealerPrimaryLogo']),
        pageName() {
            return this.profileName || this.currentDealer.name || this.adData?.platform_specific_fields?.snapchat_page?.name;
        },
        pageLogo() {
            return this.profileLogo || this.dealerPrimaryLogo?.url || this.defaultLogoUrl;
        },
        profileName() {
            return this.adData?.platform_specific_fields?.profile?.name;
        },
        profileLogo() {
            return this.adData?.platform_specific_fields?.profile?.picture;
        },
        creatives() {
            return this.adData?.creatives ?? [];
        },
        sanitizedPrimaryText() {
            const primaryText = this.adData?.primary_text || this.creatives[0]?.title;
            return primaryText?.slice(0, lengthValidatorConstants[primaryTextKey][META].maxLength);
        },
        sanitizedHeadline() {
            // confirm keys
            const headline = this.adData?.headline || this.creatives[0]?.headline;
            return headline?.slice(0, lengthValidatorConstants[headlineKey][META].maxLength);
        },
        sanitizedDescription() {
            // confirm keys
            const description = this.adData?.description || this.creatives[0]?.description;
            return description?.slice(0, lengthValidatorConstants[descriptionKey][META].maxLength);
        },
    },
    watch: {
        showmore() {
            if(this.showmore === true) {
                this.truncateDescription(this.sanitizedPrimaryText, true);
            }
        }
    },
    mounted() {
        this.truncateDescription(this.sanitizedPrimaryText, false);
        this.slideLength = this.creatives.length;
    },
    methods: {
        truncateDescription(desc, showAll) {
            if (desc?.length > 100 && !showAll) {
                this.desc = desc.slice(0, 100) + '...';
            }  else {
                this.desc = this?.adData?.body_text;
            }
        },
        slideChange(i) {
            this.currentSlide = i + 1;
        },
        playVideo(i) {
            const videoRef = 'video'+i;
            const iconRef = 'video-icon'+i;
            this.$refs[videoRef][0].play();
            this.$refs[iconRef][0].style.display = 'none';
        },
    }
};
</script>

<style lang="scss" scoped>
.preview-container{
    position: relative;
    width: 375px;
    display: flex;
    flex-direction: column;
    background-color:$white;
    border-radius: 20px;
    box-shadow: 0 0 15px 5px rgba(0,0,0,0.2);
    @media only screen and (max-width: 599px) {
        width: 375px;
    }
    @media only screen and (max-width: 400px) {
        width: 320px;
    }
}
.preview-ui{
    position: relative;
    display: flex;
    height:100%;
    flex-direction: column;
}
.top-elements{
    margin-top:5px;
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    padding: 10px 15px;
}
.top-dots{
    display: flex;
    align-items: center;
    white-space: nowrap;
}
.top-dot{
    display: inline-block;
    margin-left: 5px;
}
.logo{
    width:40px;
    height:40px;
    border-radius: 50%;
}
.top-info{
    display: flex;
}
.top-desc{
    margin-left:8px;
}
.top-title{
    font-size: 15px;
    font-weight: 700;
    line-height: 18px;
    margin-top:2px;
}
.top-sponsored{
    font-size: 13px;
    color: $gray;
}
.description{
    font-size: 14px;
    padding: 15px;
    overflow: hidden;
}
.showmore{

    cursor: pointer;
    display: inline-block;
    font-weight: 700;
}
.block{
    width: 333px;
    padding: 15px;
    box-sizing: border-box;
    @media only screen and (max-width: 400px) {
        width: 280px;
    }
}
.image-holder{
    width: 100%;
    height: auto;
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    min-height: 303px;
    background:black;
    border-top-left-radius: 10px;
    border-top-right-radius: 10px;
    img, video{
        border-top-left-radius: 10px;
        border-top-right-radius: 10px;
        display: inline-block;
        max-width: 100%;
        max-height: 303px;
        height: auto;
        vertical-align: top;
    }
}


.block-elements{
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    padding: 15px;
    border: 1px solid $gray-light;
    border-bottom-left-radius: 10px;
    border-bottom-right-radius: 10px;
    background-color: #F1F2F5;
}

.block-title{
    font-size: 15px;
    color:black;
    font-weight: 700;
}
.block-desc{
    font-size: 13px;
    color: #6C6D71;
}
.block-link a{
    width: 100px;
    height: 36px;
    display: inline-flex;
    text-align: center;
    align-items: center;
    justify-content: center;
    background: #D4D4D4;
    border-radius: 5px;
    color:black;
    text-decoration: none;
    font-weight: 700;
    font-size: 13.151515px;
    margin-top:5px;
    font-style: normal;
    letter-spacing: 0px;
    &:hover{
        background: $gray-light;
    }
}
.border{
    border-bottom: 1px solid #979797;
    margin: 10px;
}
.low-elements{
    padding:10px;
    display: flex;
    justify-content: space-around;
}
.slide-num{
    position: absolute;
    bottom:-58px;
    width: 50px;
    left:50%;
    margin-left:-20px;
}
.play-wrapper{
    position: absolute;
    top:0;
    right:0;
    left:0;
    bottom:0;
    cursor: pointer;
    z-index: 3;
    .play{
        position: absolute;
        top:50%;
        left:50%;
        margin-top:-20px;
        margin-left:-20px;
        cursor: pointer;
        position: relative;
        z-index: 2;
    }
    &:after{
        content:'';
        position: absolute;
        width: 85px;
        border-radius: 50%;
        border: 1px solid white;
        height: 85px;
        top:50%;
        left:50%;
        margin-top:-44px;
        margin-left:-45px;
        background: rgba(0,0,0,0.5);
        cursor: pointer;
        z-index: 1;
    }
}
</style>

<style lang="scss">
.facebook-preview-carousel-mobile{
    .VueCarousel-wrapper{
        width: 375px;
        @media only screen and (max-width: 599px) {
            width: 375px;
        }
        @media only screen and (max-width: 400px) {
            width: 320px;
        }
    }
    .VueCarousel-inner{
        width: 333px;
        @media only screen and (max-width: 400px) {
            width: 280px;
        }
    }
    .VueCarousel-navigation{
        position: absolute;
        bottom:-100px;
        width: 100px;
        left: 50%;
        margin-left: -60px;
    }
    .VueCarousel-navigation-button{
        transform: translate(0) !important;
    }
    .VueCarousel-navigation-button:focus{
        outline: none !important;
    }
    .VueCarousel-navigation-prev:before {
        content: url('/img/chevron-left.svg');
        padding-right: 10px;
        position: absolute;
        filter: invert(58%) sepia(79%) saturate(653%) hue-rotate(175deg) brightness(107%) contrast(94%);
    }

    .VueCarousel-navigation-next:after {
        content: url('/img/chevron-right.svg');
        padding-left: 10px;
        position: absolute;
        filter: invert(58%) sepia(79%) saturate(653%) hue-rotate(175deg) brightness(107%) contrast(94%);
    }
}

</style>
